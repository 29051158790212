import { usePreferredLanguageV2 } from 'admin-portal-shared-services';
import { defaultNS, resources } from 'i18n';
import i18n, { Namespaces } from 'i18next';
import { PropsWithChildren, useEffect } from 'react';
import { I18nextProvider, initReactI18next } from 'react-i18next';

i18n.use(initReactI18next).init({
  fallbackLng: 'en-US',
  supportedLngs: ['en-US', 'pt-BR', 'es-AR', 'ko-KR'],
  ns: ['common', 'accounts', 'orders'] as Array<Namespaces>,
  defaultNS,
  resources,
});

export function I18nProvider(props: PropsWithChildren<object>) {
  const { defaultLanguage, preferredLanguage } = usePreferredLanguageV2();

  const latamLanguague = 'es-419';
  const spanhishForArLanguage = 'es-AR';

  const selectedLanguage =
    preferredLanguage === latamLanguague ? spanhishForArLanguage : preferredLanguage;

  useEffect(() => {
    const changeLanguage = () => {
      const language = selectedLanguage || defaultLanguage;
      i18n.changeLanguage(language);
    };
    changeLanguage();
  }, [defaultLanguage, selectedLanguage]);

  return <I18nextProvider i18n={i18n}>{props.children}</I18nextProvider>;
}
