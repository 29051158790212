import {
  useAppHeader,
  useAuthenticationService,
  useUserMetadata,
  useHasPermission,
} from 'admin-portal-shared-services';
import { useEffect } from 'react';
import { userPermissions } from 'routes/Definition';

export const AppHeader = () => {
  const [, setAppHeaderConfig] = useAppHeader();
  const { data } = useUserMetadata();
  const authentication = useAuthenticationService();
  const { user_country } = authentication.getUserCountryAndLanguage();

  const hasUserPermission = useHasPermission(userPermissions.all, 'OR');

  useEffect(() => {
    if (hasUserPermission) {
      setAppHeaderConfig({
        countryOptions: data?.supportedCountries || [],
        vendorSelect: true,
        countrySelect: true,
        defaultCountry: `${user_country}`,
      });
    }
  }, [setAppHeaderConfig, data?.supportedCountries, user_country]);

  return null;
};
