import { Error403 } from '@hexa-ui/components';
import { useHasPermission } from 'admin-portal-shared-services';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { homePath } from 'routes/Definition';

interface Props {
  children: React.ReactElement;
  permission: string | string[];
  isEnabled?: boolean;
}

export const ProtectedRoute = ({ isEnabled = false, children, permission }: Props) => {
  const navigate = useNavigate();
  const { t } = useTranslation('chainManagement');

  const hasUserPermission = useHasPermission(permission, 'OR');
  return isEnabled && hasUserPermission ? (
    <>{children}</>
  ) : (
    <div style={{ height: '80vh' }}>
      <Error403
        header={t('accessDenied.title')}
        description={t('accessDenied.subtitle')}
        buttonText={t('accessDenied.buttonText')}
        onButtonClick={() => {
          navigate(homePath);
        }}
      />
    </div>
  );
};
