import { AppHeader } from 'components/AppHeader/AppHeader';
import { useSegmentAnalytics } from 'hooks/useSegmentAnalytics';
import { EnvConfig, GlobalProvider } from 'providers';
import { Router } from './routes';

export default function App(props: EnvConfig) {
  const { SEGMENT_KEY } = props;
  useSegmentAnalytics(SEGMENT_KEY);

  return (
    <GlobalProvider env={props}>
      <AppHeader />
      <Router />
    </GlobalProvider>
  );
}
