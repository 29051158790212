import { ChartTree, Home, Store, UserProfile } from '@hexa-ui/icons';
import { PageLoader } from 'components/PageLoader/PageLoader';
import { ProtectedRoute } from 'components/ProtectedRoute';
import { Namespaces } from 'i18next';
import { ElementType, LazyExoticComponent, ReactElement, Suspense, lazy } from 'react';

export type Modules =
  | 'HOME'
  | 'CHAIN'
  | 'CHAINMANAGEMENT'
  | 'CHAINMANAGEMENTCREATE'
  | 'UPDATEREVIEW'
  | 'ACCOUNTDETAILS'
  | 'INFORMATIONCENTER'
  | 'CUSTOMERLIST'
  | 'CHAINUPDATE'
  | 'UPLOADSPREADSHEET';
//| 'POCSEGMENTATION';

type Route = {
  path: string;
  Element?: LazyExoticComponent<() => ReactElement>;
  children?: Route[];
};

type ModulesSideBar = Exclude<
  Modules,
  | 'CHAINMANAGEMENT'
  | 'CHAINMANAGEMENTCREATE'
  | 'ACCOUNTDETAILS'
  | 'UPDATEREVIEW'
  | 'CHAINUPDATE'
  | 'UPLOADSPREADSHEET'
>;

type RoutesProps = Record<Modules, Route>;

type SidebarProps = Record<ModulesSideBar, { id: Namespaces; path: string; icon: ElementType }>;

export const userPermissions = {
  read: 'LinkAdmin.ChainM.Read',
  write: 'LinkAdmin.ChainM.Write',
  all: ['LinkAdmin.ChainM.Read', 'LinkAdmin.ChainM.Write'],
};

export const ChainPage = lazy(() => import('views/Chain'));
export const ChainManagementPage = lazy(() => import('views/ChainManagement'));
export const AccountDetailsPage = lazy(() => import('views/AccountDetails'));
export const ChainUpdatePage = lazy(() => import('views/ChainUpdate'));
export const UpdateReviewPage = lazy(() => import('views/UpdateReview'));
export const UploadSpreadSheetPage = lazy(() => import('views/ChainUpdate/UploadSpreadSheet'));

export const LINK_ADMIN_ROUTE = 'link-admin';
export const BASE_NAME = 'bees-chain-management';

export const homePath = `/${LINK_ADMIN_ROUTE}`;
export const chainPath = `/${BASE_NAME}`;
export const chainManagementPath = `/${BASE_NAME}/chain-management/`;
export const accountDetailsPath = `/${BASE_NAME}/account-details/`;
export const chainUpdatePath = `/${BASE_NAME}/chain-update`;
export const updateReviewPath = `/${BASE_NAME}/chain-update/update-review`;
export const uploadSpreadSheetPath = `${chainUpdatePath}/upload-spreadsheet`;

export const informationCenterPath = `/bees-information-center`;
export const customerListPath = '/customer-list';
export const pocSegmentationPath = '/bees-poc-segment';

export const Routes: RoutesProps = {
  CHAIN: { path: chainPath, Element: ChainPage },
  HOME: { path: homePath },
  CHAINMANAGEMENT: {
    path: `${chainManagementPath}:isCreate/:chainName`,
    Element: ChainManagementPage,
  },
  CHAINMANAGEMENTCREATE: {
    path: `${chainManagementPath}`,
    Element: ChainManagementPage,
  },
  ACCOUNTDETAILS: {
    path: `${accountDetailsPath}:chainName/:country`,
    Element: AccountDetailsPage,
  },

  CHAINUPDATE: {
    path: `${chainUpdatePath}`,
    Element: ChainUpdatePage,
  },
  UPDATEREVIEW: {
    path: `${updateReviewPath}`,
    Element: UpdateReviewPage,
  },
  UPLOADSPREADSHEET: {
    path: `${uploadSpreadSheetPath}`,
    Element: UploadSpreadSheetPage,
  },
  INFORMATIONCENTER: { path: informationCenterPath },
  CUSTOMERLIST: { path: customerListPath },
  //POCSEGMENTATION: { path: pocSegmentationPath },
};

export const Sidebar: SidebarProps = {
  HOME: {
    id: 'common',
    path: `/${LINK_ADMIN_ROUTE}`,
    icon: () => <Home />,
  },
  INFORMATIONCENTER: {
    id: 'informationCenter',
    path: informationCenterPath,
    icon: () => <UserProfile />,
  },
  CUSTOMERLIST: {
    id: 'customerList',
    path: `${customerListPath}`,
    icon: () => <ChartTree />,
  },
  CHAIN: {
    id: 'chainManagement',
    path: `${BASE_NAME}`,
    icon: () => <Store />,
  },
  // POCSEGMENTATION: {
  //   id: 'pocSegmentation',
  //   path: `${pocSegmentationPath}`,
  //   icon: () => <List3 />,
  // },
};

export const sidebar = [
  Sidebar.HOME,
  Sidebar.INFORMATIONCENTER,
  Sidebar.CUSTOMERLIST,
  Sidebar.CHAIN,
  //Sidebar.POCSEGMENTATION,
];
export const modules = [
  Routes.HOME,
  Routes.CHAINMANAGEMENT,
  Routes.CHAINMANAGEMENTCREATE,
  Routes.ACCOUNTDETAILS,
  Routes.CHAINUPDATE,
  Routes.UPDATEREVIEW,
  Routes.CHAINUPDATE,
  Routes.UPLOADSPREADSHEET,
  Routes.CHAIN,
];

/* istanbul ignore next */
export const routesConfig = modules.map(({ path, Element }) => ({
  path,
  element: (
    <Suspense fallback={<PageLoader />}>
      <ProtectedRoute isEnabled permission={userPermissions.all}>
        {Element ? <Element /> : <></>}
      </ProtectedRoute>
    </Suspense>
  ),
}));
