import { PropsWithChildren } from 'react';
import {
  EnvConfig,
  EnvironmentProvider,
  I18nProvider,
  QueryClientProvider,
  ThemeProvider,
} from '../index';

export function GlobalProvider(props: PropsWithChildren<{ env: EnvConfig }>) {
  return (
    <ThemeProvider>
      <EnvironmentProvider {...props.env}>
        <I18nProvider>
          <QueryClientProvider>{props.children}</QueryClientProvider>
        </I18nProvider>
      </EnvironmentProvider>
    </ThemeProvider>
  );
}
